@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary-lightest: #ececfd;
  --color-primary-light: #d9d9fc;
  --color-primary-lighten: #8e8ef5;
  --color-primary-default: #6363f1;
  --color-primary-darken: #4747eb;
  --color-primary-dark: #1616ca;
  --color-primary-darkest: #060637;
  --color-primary-soft: #eeeffc;
}

svg {
  /* tailwind uses `block` and that causes a few bugs with svgs */
  display: inline-block;
}
